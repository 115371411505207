import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import { Trans } from "gatsby-plugin-react-i18next"
import Trans from '../components/Trans'

const NotFoundPage = ({ location }) => (
  	// <Layout location = {location} className="text-center">
    // 	<Seo title="404: Not found" />
    // 	<div className="text-center md:py-40">
    //   		<h1 className="text-xl md:text-6xl text-gray-500 font-bold">404: <Trans>Not Found</Trans></h1>
    //   		<p className="py-5 mb-0"><Trans>You just hit a route that doesn't exist.</Trans></p>
    //   		{/* <p><Trans i18nKey="gohome">Go <Link href="/">Home</Link> to start over.</Trans></p> */}
    //   		<p><Trans> gohome <Link href="/"><Trans>Home</Trans></Link> <Trans>to start over.</Trans></Trans></p>
    // 	</div>
  	// </Layout>
	  <>
		  <div className="bg-indigo-100 w-full grid place-content-center text-center py-16">
        <h2 className="homepage-heading text-purple-100">Oops... 404</h2>
        <p className="text-purple-100 text-center">Page not found</p>
      </div>
	  </>
)

export default NotFoundPage

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
